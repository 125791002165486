.footer-container {
   position: fixed;
   bottom: 0;
   display: flex;
   flex-direction: column;
   width: 100%;

}

.footer {
   background-color: black;
   color: white;
   width: 100%;
   text-align: center;
   padding: 7px 0;
   font-weight: 600;
}

