.class-card {
  background-color: #f5f5f5;
  margin: 10px 0;
  padding: 5px 0 5px 10px;
}

.class-card p,
.class-card h6 {
  margin: 0;
  color: #000;
}

.class-card h6 {
  font-weight: 700;
}

.class-card svg:hover {
  color: #608bfb;
}

.date-icon {
  margin-top: 10px;
  background-color: #fff;
  border: 1px solid #8b8b8b;
}

.date-icon p {
  font-size: 32px;
}

.date-icon .month {
  background-color: #608bfb;
  color: #fff;
}

.link-styled-button {
  border: none;
  color: #608bfb;
}

.instructors {
  margin-top: 3vh;
}

.instructors img,
h3 {
  margin: 20px 0;
}

.img-circle {
  height: 200px;
  width: 200px;
  object-fit: cover;
  border-radius: 50%;
}

.illuminated {
  box-shadow: inset 4px 4px #6ba1d3b6, 4px 4px 4px 4px #365b7d9d;
}