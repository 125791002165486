.hero-list-item {
  background-color: #ddd;
  margin: 5px 0;
  padding: 5px;
  border-radius: 4px;
}

.no-style {
  background: none;
  border: none;
}

.link-style {
  color: blue;
  font-weight: 800;
}
