.navbar-light .navbar-brand a {
  color: #fff;
  font-weight: 700;
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: #fff;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar a {
  color: rgba(255, 255, 255, 0.5);
  transition: 0.3s;
}

.navbar a:hover {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
}

.dropdown-menu a:hover {
  color: #365b7d;
}

.dropdown-menu a {
  color: black;
}

.navlink {
  margin-left: 20px;
  padding: 8px 0;
  color: black;
}

.dropdown-item {
  color: white;
}