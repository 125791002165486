/* .dark-overlay-main {
  background: rgba(54, 91, 125, 0.3);
} */

@media(min-width: 1360px) { 
  .header-main {
  height: max-content;
  width: 100%;
  background-image: url("../../img/scaleflow.jpg");
  background-position: 0 3rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
  padding: 20rem 0 4rem;
}
}

@media(max-width: 1360px) {
  .header-main {
    padding: 5rem 0 4rem;
  }
}

@media(min-width: 1800px) {
  .header-main {
    padding: 25rem 0 5rem;
  }
}

@media(min-width: 2200px) {
  .header-main {
    padding: 30rem 0 6rem;
  }
}

@media(min-width: 2450px) {
  .header-main {
    padding: 35rem 0 7rem;
  }
}

@media(min-width: 2750px) {
  .header-main {
    padding: 40rem 0 8rem;
  }
}

@media(min-width: 3000px) {
  .header-main {
    padding: 45rem 0 9rem;
  }
}

@media(min-width: 3325px) {
  .header-main {
    padding: 50rem 0 10rem;
  }
}

@media(min-width: 3600px) {
  .header-main {
    padding: 55rem 0 15rem;
  }
}

.hero {
  margin: 0 auto;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
}
h1 {
  font-size: 2rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #365b7d;
}
p {
  color: #365b7d;
  font-weight: 500;
}

.hero h5 {
  margin-bottom: 1rem;
}

.link-button {
  background-color: #2a92d8;
  color: #fff;
  padding: 0.7rem 0.5rem;
  border-radius: 4px;
}

.link-button a {
  margin: 30px;
}

.link-button:hover {
  color: #fff;
  background-color: #2480bd;
  text-decoration: none;
}

.content {
  margin: 1rem 0 5rem;
}

.row-style {
  padding: 10px 0;
}

.learn-more-box {
  background-color: #365b7d;
  border-radius: 4px;
  padding: 20px 20px 30px;
  height: fit-content;
}

.learn-more-box h3,
.learn-more-box p {
  margin-bottom: 20px;
  color: white;
}

.contact-info-box {
  border: 1px solid #365b7d;
  border-radius: 4px;
  padding: 30px 30px;
}

h6.light-grey-header {
  color: #444;
  font-weight: 800;
}

.icon {
  color: #2480bd;
  margin: 5px 0 0 0;
}