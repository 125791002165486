.cookies-modal{
    width: 100%;
    height: 75px;
    background-color: rgba(0, 0, 0, 0.856);
    z-index: 10;
}

.cookies-modal__content {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    height: inherit;
    align-items: center;
}

.cookies-modal__text {
    color: white;
}

.cookies-modal__button {
    margin: 20px;
    padding: 8px;
    border-radius: 4px;
    border: none;

}

.cookies-modal__close {
    position: absolute;
    top: 10px;
    right: 20px;
    color: white;
    cursor: pointer;
}
